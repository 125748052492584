<template>
    <div class="object_detail" style="margin-right: 15px; margin-left:15px">
        <div class="object_detail form-control">{{ title }}</div>
        <input type='text' v-model="localVal" @keypress.enter="save" @blur="save" class="form-control" style="width: 100px;"/>
    </div>
</template>

<script>
export default {
    name: "DetailsText",
    props: ["field", "val", "title", "id"],
    data() {
        return {
            localVal:'',
        }
    },
    methods: {
        save: function() {
            this.$emit('save', {'field': this.field, 'val': this.localVal, 'id': this.id});
        }
    },
    watch: {
        val: {
            immediate: true,
            handler: function () {
                this.localVal = this.val;
            }
        }
    },
}
</script>

<style scoped>
.form-control {
    white-space: pre-wrap;
    height: auto;
}
</style>