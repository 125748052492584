<template>
    <div class="object_detail" style="margin-right: 15px; margin-left:15px">
        <div class="object_detail">{{ title }}</div>
        <div class="form-control">{{ val }}</div>
    </div>
</template>

<script>
export default {
    name: "DetailsStatic",
    props: ["title", "val", "id"]
}
</script>

<style scoped>
.form-control {
    white-space: pre-wrap;
    height: auto;
}
</style>