export const inventoryManagerStore = {
    state: {
        name: 'inventory_manager',
        title: 'Inventory Manager',
        titlePlural: 'Inventory',
        nav: {
            base: 'inventory_manager',
            newView: false,
            hideUpload: true,
            views: [
                {
                    view: 'batching',
                    label: 'Batching',
                    alwaysVisible: true
                },
                {
                    view: 'metal_snapshot',
                    label: 'Weekly Metal Inventory',
                    alwaysVisible: true
                }
            ]
        },
        search: {
            endPoint: 'inventory_manager',
            formFields: {
                location_id: {
                    val: 1,
                    qsField: '[location_id]',
                    label: 'Location',
                },
            },
            resultFields: {
                inventory_materials: {
                    inventory_min: {header: 'Inventory Min', link: ''},
                    inventory_max: {header: 'Inventory Max', link: ''},
                    location_id: {header: 'Location', link: ''},
                    qty_on_order: {header: 'On Order', link: ''},
                    qty_reserved: {header: 'Reserved', link: ''},
                    raw_material: {header: 'Raw Material', link: ''},
                    raw_material_id: {header: 'Raw Material ID', link: ''},
                    raw_material_inventory: {header: 'Inventory', link: ''},
                },
                batching_materials: {
                    inventory_min: {header: 'Inventory Min', link: ''},
                    inventory_max: {header: 'Inventory Max', link: ''},
                    location_id: {header: 'Location', link: ''},
                    qty_on_order: {header: 'On Order', link: ''},
                    qty_reserved: {header: 'Reserved', link: ''},
                    raw_material: {header: 'Raw Material', link: ''},
                    raw_material_id: {header: 'Raw Material ID', link: ''},
                    raw_material_inventory: {header: 'Inventory', link: ''},
                },
            },
        },
        object: {
            fields: [
                {
                    name: 'quantity_reserved',
                    label: 'Reserved:',
                    component: 'DetailsStatic'
                },
                {
                    name: 'inventory_min',
                    label: 'Inventory Min:',
                    component: 'DetailsText'
                },
                {
                    name: 'inventory_max',
                    label: 'Inventory Max:',
                    component: 'DetailsText'
                },
                {
                    name: 'raw_material_inventory',
                    label: 'Inventory:',
                    component: 'DetailsText'
                },
                {
                    name: 'qty_on_order',
                    label: 'On Order:',
                    component: 'DetailsStatic'
                },
                {
                    name: 'quantity_available',
                    label: 'Available:',
                    component: 'DetailsStatic'
                }
            ],
        }
    }
}