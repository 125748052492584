<template>
    <div>
        <h1>{{ header }}</h1>
        <div class="panel panel-default" v-show="!isSnapshotView">
            <div class="panel-heading"></div>
            <div class="row panel-body">
                <form>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>Location</label>
                        <select class="form-control" v-model="cdiLocationID" @change="load()">
                            <option v-for="[cdiLocationID, cdiLocation] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="cdiLocationID" :value="cdiLocationID">{{ cdiLocation }}</option>
                        </select>
                    </div>
                </form>
            </div>
        </div>
        <MaterialDetail :data="state.search.results.materials" :heading="'Inventory Manager'" v-show="isCurrentView('search')"  @save="save" />
        <MaterialDetail :data="state.search.results.materials" :heading="'Batching Inventory Manager'" v-show="isCurrentView('batching')"  @save="save"/>
        <SnapshotInventory :v-show="isCurrentView('metal_snapshot')" :inventoryTypeID="cdiVars.INVENTORY_TYPE_STEEL" />
    </div>
</template>

<script>
import { store } from "@/store/BusinessObject.store.js";
import { inventoryManagerStore } from "@/components/tools/InventoryManager/InventoryManager.store";
import MaterialDetail from "@/components/tools/InventoryManager/Search";
import cdiLocationCache from '@/cache/cdiLocation.cache';
import SnapshotInventory from "@/components/tools/InventoryManager/SnapshotInventory.vue";
import {cdiVars} from "@/cdiVars";


export default {
    name: "Inventory_Manager",
    components:
        {SnapshotInventory, MaterialDetail},
    data() {
        return {
            state: store.state,
            inventoryManagerState: inventoryManagerStore.state,
            cdiLocationID: 1,
            inventory_type: '',
            cache: {
                cdiLocationCache,
            },
            snapshotViews: [
                'metal_snapshot'
            ]
        }
    },
    created() {
        store.initialize(this.inventoryManagerState);
        this.load()
    },
    methods: {
        isCurrentView: function(view) {
            return view === this.state.currentView;
        },
        load: function() {
            if (this.currentView === 'search') {
                this.inventory_type = 'manage_inventory'
            } else if(this.currentView === 'batching') {
                this.inventory_type = 'batching_inventory'
            } else if(this.currentView === 'metal_snapshot') {
                this.inventory_type = cdiVars.INVENTORY_TYPE_STEEL
            }
            store.api('to_json', {
                'params[cdi_location_id]': this.cdiLocationID,
                'params[inventory_type]': this.inventory_type,
            }, 'search.results')
        },
        save: function(item) {
            let params = {};
            params.params = {};
            params.params.field = item.field;
            params.params[item.field] = item.val;
            params.params.cdi_location_id = this.cdiLocationID;
            params.params.raw_material_id = item.id;
            params.params.inventory_type = this.inventory_type;
            store.customApi(`inventory_manager/save_inventory/${params.params.raw_material_id}`, params);
            this.load();
        }
    },
    computed: {
        cdiVars() {
            return cdiVars
        },
        currentView: function() {
            return this.state.currentView;
        },
        header: function() {
            switch (this.currentView) {
                case 'batching':
                    return 'Batching Inventory Manager';
                case 'metal_snapshot':
                    return 'Weekly Metal Inventory';
                default:
                    return 'Inventory Manager'
            }
        },
        isSnapshotView: function() {
            return this.currentView.includes('snapshot')
        }
    },
    watch: {
        currentView: function() {
            if (this.state.currentView == 'history'){
                store.getHistory();
            } else {
                this.load();
            }
        }
    }
}
</script>

<style scoped>

</style>