<template>
    <div>
        <h3>{{ header }} <span class="pull-right">Last Inventory Update: {{ lastUpdated }}</span></h3>
        <div class="form-group">
            <label>CDI Location</label>
            <select v-model="selectedCdiLocation" class="form-control">
                <option value="0">Not Selected</option>
                <option v-for="[cdiLocationID, cdiLocation] in cache.cdiLocation" :key="cdiLocationID" :value="cdiLocationID">{{ cdiLocation }}</option>
            </select>
        </div>
        <div class="table-responsive">
        <table class="table">
            <thead>
            <tr>
                <th>Material ID</th>
                <th>Material</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in inventoryItems" :key="item.raw_material_id">
                <td>{{ item.raw_material_id }}</td>
                <td>{{ item.raw_material }}</td>
                <td class="number"><input class="form-control" v-model="item.inventory_count" type="number" /></td>
            </tr>
            </tbody>
        </table>
        </div>
        <button v-show="this.inventoryItems.length" @click="showFinalizeBox" class="btn btn-primary">Save Inventory</button>
        <Confirm ref="finalizeBox" :heading="'Finalize Inventory Counts?'" :body="'You will not be able to edit these numbers after submission. Please review your counts and make any necessary changes before proceeding.'" @response="finalizeInventory" />
    </div>
</template>

<script>

import cdiLocationCache from "@/cache/cdiLocation.cache";
import { store } from '@/store/Tool.store';
import Confirm from "@/components/utils/Confirm.vue";

export default {
    components: { Confirm },
    props: {
        inventoryTypeID: {
            default: 0,
            type: Number
        }
    },
    data() {
        return {
            selectedCdiLocation: 0,
            inventoryItems: [],
            lastUpdated: '',
            cache: {
                cdiLocation: cdiLocationCache.CDI_LOCATIONS
            }
        }
    },
    methods: {
        getFifoItems: function() {
            let scope = this;
            let options = {
                params: {
                    cdi_location_id: scope.selectedCdiLocation,
                    inventory_type_id: scope.inventoryTypeID
                }
            };
            store.api('/inventory_manager/get_fifo_inventory', options).then(function(res) {
                scope.inventoryItems = res.data.materials,
                scope.lastUpdated = res.data.last_reported_date;
            });
        },
        showFinalizeBox: function() {
            this.$refs.finalizeBox.open(true);
        },
        finalizeInventory(res) {
            if (res) {
                let scope = this;
                let options = {
                    params: {
                        raw_materials: scope.inventoryItems,
                        cdi_location_id: scope.selectedCdiLocation,
                        inventory_type_id: scope.inventoryTypeID
                    }
                }
                store.api('/inventory_manager/save_fifo_inventory', options).then(function(res) {
                    scope.inventoryItems = res.data.materials;
                    scope.lastUpdated = res.data.last_reported_date
                });
            }
        }
    },
    computed: {
        header: function() {
            if (this.inventoryTypeID) {
                switch (this.inventoryTypeID) {
                    case 4:
                        return 'Metal';
                    default:
                        return 'Invalid Type'
                }
            } else {
                return '';
            }
        }
    },
    watch: {
        selectedCdiLocation: function() {
            this.getFifoItems();
        }
    }
}

</script>